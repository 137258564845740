import { Button, CircularProgress, LinkHandler } from '@/components/atoms';
import { CardSlider } from '@/components/molecules';
import {
  brandViewModel,
  IBrandCard,
} from '@/components/organisms/ArtisanWithProducts/BrandViewModel';
import { useResult } from '@/hooks/useResult';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import styled from 'styled-components';

const Loader = styled.div`
  display: flex;
  min-height: 520px;
  align-items: center;
  justify-content: center;
`;
const LinkContainer = styled.div`
  text-align: center;
  margin-top: 32px;
`;

interface Iart {
  url: string;
  artisan_slug: string[] | undefined;
  label: string | null;
  target: string | null;
}

const ArtisanCarousel: React.FC<Iart> = props => {
  const { artisan_slug, url, label, target } = props;
  const router = useRouter();

  const [result, setResult] = useResult<IBrandCard[]>();

  useEffect(() => {
    if (artisan_slug && artisan_slug.length > 0) {
      async function loadBrands() {
        const result = await brandViewModel.getBrandForCard(
          artisan_slug as string[]
        );
        setResult(result);
      }
      loadBrands();
    }
  }, [router.asPath]);

  return result.status === 'ok' && result.data.length > 0 ? (
    <>
      <CardSlider
        withScrollBar={false}
        typeCard={'artisan'}
        cards={result.data}
        template={'carousel'}
      />
      {url && (
        <LinkContainer>
          <LinkHandler href={url} passHref>
            <a target={target || '_self'}>
              <Button>{label}</Button>
            </a>
          </LinkHandler>
        </LinkContainer>
      )}
    </>
  ) : (
    <Loader>
      <CircularProgress />
    </Loader>
  );
};

export default ArtisanCarousel;
